import { useStaticQuery, graphql } from "gatsby"
export const useAllProducts = () => {
  const { allAllProductsJson } = useStaticQuery(
    graphql`
      query {
        allAllProductsJson {
          nodes {
            id
            name
            slug
            categories
            description
            image
            price
          }
        }
      }
    `
  )
  return allAllProductsJson.nodes
}
