import React, { useState } from "react"
import { Link } from "gatsby"
import first from "lodash/first"
import classnames from "classnames"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useAllProducts } from "../../hooks/useAllProducts"
import "./product.css"
const Product = ({ pageContext }) => {
  const data = useAllProducts()
  console.log(pageContext.image)

  const img = first(pageContext.image)
    ? first(pageContext.image)
    : "https://via.placeholder.com/250"

  const [activeImg, setActiveImg] = useState(img)

  return (
    <Layout mainclass="product-info-page">
      <SEO title={pageContext.name} />
      <div className="product-info">
        <div className="center">
          <div className="product-images__container">
            <div
              className="product-image"
              style={{ backgroundImage: `url("/products/${activeImg}")` }}
            ></div>
            <div className="product-images__carousel-selector">
              {pageContext.image.map(i => (
                <button
                  onClick={() => setActiveImg(i)}
                  type="button"
                  className={classnames("product-image", {
                    active: i === activeImg,
                  })}
                  style={{ backgroundImage: `url("/products/${i}")` }}
                ></button>
              ))}
            </div>
          </div>

          <div>
            <h1>{pageContext.name}</h1>
            {pageContext.description.split("\\n").map(p => (
              <p key={p} className="description">
                {p}
              </p>
            ))}
            <h3>Ota yhteyttä</h3>
            <p>
              Kerrothan meille tarvittavan määrän, sekä toivotun toimitusajan
              yhteydenotossasi.
            </p>
            <Link to="/yhteystiedot" className="btn">
              Ota yhteyttä
            </Link>
          </div>
        </div>
      </div>
      <h2>Muut tuotteet</h2>
      <div className="products-container">
        {data.map(p => {
          const img = first(p.image)
            ? `/products/${first(p.image)}`
            : "https://via.placeholder.com/250"

          return (
            <Link key={p.slug} to={`/tuote/${p.slug}`} className="product-card">
              <div
                className="product-image"
                style={{ backgroundImage: `url("${img}")` }}
              ></div>
              <div className="product-info">
                <p className="product-title">
                  <strong>{p.name}</strong>
                </p>
                <p className="btn">Lue lisää</p>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default Product
